import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import styled from '@emotion/styled'

// components
import SEO from '@components/SEO'
import Hr from '@components/Hr'
import CarouselBodyButton from '@components/CarouselBodyButton'
//import Hero from './components/Hero'
import HeroSmall from './components/HeroSmall'
import CollapsePosition from './components/CollapsePosition'
import Quotes from './components/Quotes'
//import Menu from './components/Menu'
import Blocks from './components/Blocks'

// graphql
import { careersContent } from './modules/graphql'

const Careers = styled(({ className }) => {
  const seoData = careersContent()[0]
  const heroData = careersContent()[1]
  const headerDescriptionBlockData = careersContent()[2]
  const careersData = careersContent()[3]
  const listStandartData = careersContent()[4]
  const carouselData = careersContent()[5]
  const cardsStandardData = careersContent()[6]
  //const menuData = careersContent()[7]

  return (
    <div className={`${className}`}>
      <SEO title={seoData.title} description={seoData.description} />
      {/* Hero */}
      <div className="">
        <div className="d-inline-block w-100">
          <div className="d-inline-block w-100">
            <HeroSmall data={heroData} />
          </div>
        </div>
      </div>
      <div className="max-width-body">
        <section className="d-inline-block w-100 careers-container-title">
          <div>
            <Container>
              <Row className="justify-content-center">
                {/*
                <Col xs="12" md="5" lg="4" className="menu-wrapper">
                  <Menu data={menuData} />
                </Col>
                */}
                <Col xs="12" md="12" lg="7">
                  <div
                    className="headerDescriptionText"
                    dangerouslySetInnerHTML={{
                      __html: headerDescriptionBlockData.content
                    }}
                  ></div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>

        {/* Open positions title */}
        <section className="w-100 careers-caregory-title d-none">
          <div>
            <Container>
              <Row>
                <Col>
                  <h3>{careersData.title}</h3>
                  <Hr className="mb-0" />
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        {/* Positions list */}
      </div>

      <section className="d-inline-block w-100 category-block">
        <Container>
          {careersData.careercategories.map((item, careersIndex) => {
            //const positionsBox = item.careers.nodes.map((careerslist, index) => {
            return (
              <div className="category-item" key={careersIndex}>
                <CollapsePosition
                  data={item}
                  fluid={
                    item.careerCategoryImage.cCategoryImage.imageFile
                      .childImageSharp.fluid
                  }
                  index={careersIndex}
                  key={careersIndex}
                />
              </div>
            )
            //})
            //return <div key={careersIndex}>{positionsBox}</div>
          })}
        </Container>
      </section>

      {/* Life at Codistry title */}

      <section className="d-inline-block w-100 list-standart">
        <div>
          <Container>
            <Row>
              <Col>
                <h3>{listStandartData.title}</h3>
                <Hr />
                <Blocks data={listStandartData.list} />
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <section className="d-inline-block w-100 quotes-title">
        <div>
          <Container>
            <Row>
              <Col>
                <h3>{carouselData.title}</h3>
                <Hr className="mb-0" />
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      {/* Team members */}

      <section className="w-100">
        {/* --<div className="mb-5 d-inline-block w-100"> */}
        <div className="quotes-wrapper clearfix">
          <div className="max-width-body">
            <Quotes
              content={carouselData.carouselprofile}
              images={carouselData.carouselprofile}
            />
          </div>
        </div>
        {/* --</div> */}
      </section>

      <section className="w-100">
        <div className="bottom-cards">
          <div>
            <Container>
              <Row>
                <Col>
                  <CarouselBodyButton data={cardsStandardData} />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </div>
  )
})`
  h3 {
    font-size: 28px;
    line-height: 38px;

    @media (min-width: 576px) {
      font-size: 36px;
      line-height: 1.28;
      margin-bottom: 15px;
    }
  }

  /*
  @media (max-width: 576px) {
    .container,
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
      padding: 0 36px;
    }
  }
  */

  .careers-hero {
    position: relative;

    &:before {
      background: #f5c141;
      bottom: 0;
      clip-path: polygon(0 0, 0% 100%, 100% 100%);
      content: '';
      height: 50%;
      left: 0;
      position: absolute;
      width: 50%;
      z-index: 1;

      @media (min-width: 576px) {
        bottom: -3rem;
        clip-path: polygon(0 0, 0% 100%, 100% 0);
        height: 150%;
        top: 0;
        z-index: 0;
        width: 40%;
      }

      @media (min-width: 768px) {
        clip-path: polygon(0 0, 0% 100%, 102% 0);
        width: 100%;
        height: 161%;
      }

      @media (min-width: 1250px) {
        clip-path: polygon(0 0, 0% 100%, 88% 0);
        height: 185%;
      }
    }
  }

  .menu-wrapper {
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }

  .careers-container-title {
    margin-bottom: 80px;

    @media (min-width: 768px) {
      margin-bottom: 60px;
    }

    @media (min-width: 1250px) {
      margin-bottom: 80px;
    }

    h3 {
      font-size: 28px;
      line-height: 1.34;

      @media (min-width: 768px) {
        line-height: 1.21;
      }

      @media (min-width: 968px) {
        font-size: 48px;
      }
    }

    hr {
      margin-bottom: 16px;
      height: 1px;
    }

    .headerDescriptionText {
      @media (min-width: 768px) {
        padding-right: 2rem;
        margin-bottom: 0;
      }

      @media (min-width: 1250px) {
        padding: 0 8px;
      }
    }

    p {
      color: #000000;
      font-weight: 300;
      font-size: 28px;
      line-height: 33px;
      letter-spacing: 0.02em;
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }

  .careers-caregory-title {
    margin-bottom: 39px;

    @media (min-width: 768px) {
      margin-bottom: 36.8px;
    }
  }

  .list-standart {
    margin-bottom: 24px;

    h3 {
      margin-bottom: 40px;
    }

    hr {
      margin-bottom: 40px;
    }

    @media (min-width: 768px) {
      margin-bottom: 70px;
    }
  }

  .category-block {
    margin-bottom: 80px;

    .category-item {
      margin-bottom: 32px;

      &:last-child {
        border: none;
        margin-bottom: 0;
      }

      @media (min-width: 768px) {
        //padding-left: 12px;
      }
    }
  }

  .quotes-wrapper {
    padding-bottom: 0;
    position: relative;

    @media (max-width: 576px) {
      &:before {
        display: none;
      }
    }

    &:before {
      background: #e3f3e5 !important;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 11rem;

      @media (min-width: 768px) {
        bottom: -10.5rem;
      }

      @media (min-width: 1250px) {
        bottom: -12rem;
      }
    }
  }

  .quotes-title {
    margin-bottom: 78px;

    @media (min-width: 768px) {
      margin-bottom: 87px;
    }

    @media (min-width: 1250px) {
      margin-bottom: 72px;
    }

    h3 {
      margin-bottom: 40px;
    }

    hr {
      background-color: #6dbf70;
      height: 4px;
    }
  }

  .bottom-cards {
    margin-bottom: 29px;
    margin-top: 27px;
    position: relative;

    @media (min-width: 568px) {
      margin-bottom: 0;
      margin-top: 25px;
    }

    @media (min-width: 992px) {
      //margin-bottom: -40px;
      margin-bottom: 33px;
      margin-top: 39px;
      //max-width: 1088px;
    }

    @media (min-width: 1250px) {
      margin-bottom: 33px;
      margin-top: 71px;
    }
  }
`

export default Careers
