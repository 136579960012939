import React, { useState, useEffect, useRef } from 'react'
import { array } from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Slider from 'react-slick'
import Img from 'gatsby-image'
import { Container } from 'react-bootstrap'

import { useWindowDimensions } from '@modules/useWindowDimensions'

// components
import ButtonPrev from '@components/ButtonPrev'
import ButtonNext from '@components/ButtonNext'

const BOXSPACING = 40

const Block = styled(({ className, data }) => {
  const { width: windowSize } = useWindowDimensions()

  const refWhy = useRef(null)
  const refMain = useRef(null)

  const [whyWrapperWidth, setWhyWrapperWidth] = useState({})
  const [mainWrapperWidth, setMainWrapperWidth] = useState({})
  const [mainGap, setMainGap] = useState({})

  useEffect(() => {
    const articlesCurrentWidth = refWhy.current.clientWidth

    const mainPosition = (whyWrapperWidth - refMain.current.clientWidth) / 2
    const mainCurrentWidth = refMain.current.clientWidth + mainPosition

    setWhyWrapperWidth(articlesCurrentWidth)

    setMainWrapperWidth(mainCurrentWidth)
    setMainGap(mainPosition)
  }, [windowSize, whyWrapperWidth, refMain])

  const settings = {
    arrows: false,
    dots: true,
    infinite: false,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <ButtonPrev />,
    nextArrow: <ButtonNext />,
    margin: 15,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true
        }
      }
    ]
  }
  return (
    <div className={`${className} w-100`} ref={refWhy}>
      <Container>
        <div ref={refMain}>
          <div
            className="limit overflow-hidden"
            css={css`
              @media (min-width: 767px) and (max-width: 991px) {
                position: relative;
                right: -${mainGap}px;
                width: ${mainWrapperWidth + BOXSPACING}px;
              }
            `}
          >
            <div className="w-100">
              <Slider {...settings}>
                {data.map((item, index) => {
                  return (
                    <div
                      className={`block-item block-item-${index}`}
                      key={index}
                    >
                      <Img
                        className="bg-img"
                        fluid={item.image.imageFile.childImageSharp.fluid}
                        alt={
                          item.image.altText ? item.image.altText : item.title
                        }
                        key={index}
                      />
                      <h4 className="title">{item.title}</h4>
                      <div className="content">{item.description}</div>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </div>
        </div>
      </Container>
      {/*
      <div className={`${className}`}>
        <Container className="p-0">
          <Row>
            {data.map((item, index) => {
              return (
                <Col xs="12" md="4" className="pr-md-0" key={index}>
                  <div className={`block-item block-item-${index}`}>
                    <Img
                      className="bg-img"
                      fluid={item.image.imageFile.childImageSharp.fluid}
                      alt={item.image.altText ? item.image.altText : item.title}
                      key={index}
                    />
                    <h4 className="title">{item.title}</h4>
                    <div className="content">{item.description}</div>
                  </div>
                </Col>
              )
            })}
          </Row>
        </Container>
      </div>
      */}
    </div>
  )
})`
  .container {
    @media (max-width: 576px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    @media (min-width: 768px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .slick-slide {
    margin: 0 10px;
  }
  .slick-list {
    margin: 0 -10px;
  }

  .slick-slider {
    .slick-dots {
      li {
        height: 10px;
        width: 10px;
        button {
          height: 10px;
          width: 10px;
          &::before {
            font-size: 34px;
            height: 10px;
            line-height: 10px;
            width: 10px;
          }
        }
      }
    }
  }

  .block-item {
    margin: 0 auto 56px;
    padding-left: 15px;
    padding-top: 15px;
    position: relative;

    .bg-img {
      background-size: cover;
      display: block;
      height: 350px;
      position: relative;

      @media (min-width: 992px) {
      }

      @media (min-width: 1250px) {
      }
    }

    .title {
      background-color: #fff;
      color: #000000;
      display: block;
      font-size: 24px;
      font-weight: 400;
      left: -1px;
      line-height: 36px;
      margin-top: -40px;
      padding: 20px 23px 0;
      position: relative;
      width: calc(100% - 20px);

      @media (min-width: 578px) {
        font-size: 24px;
        padding-left: 13px;
        width: calc(100% - 20px);
      }

      @media (min-width: 992px) {
        font-size: 28px;
        padding-left: 23px;
      }

      @media (min-width: 1250px) {
        width: calc(100% - 60px);
      }
    }

    .content {
      color: #8e8e8e;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.8px;
      font-weight: normal;
      padding-left: 13px;
      padding-right: 16px;

      @media (min-width: 968px) {
        padding-left: 23px;
        padding-right: 20px;
      }

      @media (min-width: 1250px) {
        padding-left: 23px;
        padding-right: 26px;
      }
    }

    &:before {
      background-color: #f5c141;
      clip-path: polygon(0 0, 0% 100%, 100% 0);
      content: '';
      height: 153px;
      left: 0;
      position: absolute;
      top: 0;
      width: 153px;

      @media (min-width: 1250px) {
        left: 0;
        top: 0;
        width: 280px;
        height: 280px;
      }
    }

    &.block-item-1:before {
      background-color: #6dc072;
    }

    &.block-item-2:before {
      background-color: #5b6398;
    }

    @media (min-width: 576px) {
      margin-bottom: 25px;
    }

    @media (min-width: 768px) {
      width: calc(100% - 10px);
      margin-left: auto;
      margin-right: 0;
    }

    @media (min-width: 1250px) {
      width: calc(100% - 20px);
    }
  }
`

Block.propTypes = {
  data: array.isRequired
}

Block.defaultProps = {
  data: []
}

export default Block
