import { useStaticQuery, graphql } from 'gatsby'

export const careersContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = useStaticQuery(graphql`
    {
      wpgraphql {
        page(id: "574", idType: DATABASE_ID) {
          AllPageElements {
            pageelements {
              __typename
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Seodata {
                title
                description
              }
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Herodata {
                title
                ptext {
                  paragraph
                }
                image {
                  altText
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Headerdescriptionblock {
                content
                fieldGroupName
                title
              }
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Careersdata {
                fieldGroupName
                title
                careercategories {
                  name
                  description
                  careerCategoryImage {
                    cCategoryImage {
                      altText
                      sourceUrl
                      mediaItemId
                      modified
                      imageFile {
                        childImageSharp {
                          fluid(maxWidth: 350, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                          }
                        }
                      }
                    }
                  }
                  careers(first: 10) {
                    nodes {
                      title
                      uri
                      link
                    }
                  }
                }
              }
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Liststandartdata {
                fieldGroupName
                title
                list {
                  description
                  fieldGroupName
                  title
                  image {
                    altText
                    sourceUrl
                    mediaItemId
                    modified
                    imageFile {
                      childImageSharp {
                        fluid(maxWidth: 510, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Carouselprofiledata {
                fieldGroupName
                title
                carouselprofile {
                  title
                  position
                  content
                  alt
                  image {
                    altText
                    sourceUrl
                    mediaItemId
                    modified
                    imageFile {
                      childImageSharp {
                        fluid(maxWidth: 720, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
              ... on WPGraphQL_Page_Allpageelements_Pageelements_Cardsstandarddata {
                fieldGroupName
                card {
                  title
                  text
                  link
                  imagealt
                  image {
                    altText
                    sourceUrl
                    mediaItemId
                    modified
                    imageFile {
                      childImageSharp {
                        fluid(maxWidth: 430, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  fieldGroupName
                }
              }
            }
          }
        }
        menus(where: { id: 21 }) {
          edges {
            node {
              menuItems {
                edges {
                  node {
                    url
                    label
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return [
    data.wpgraphql.page.AllPageElements.pageelements[0],
    data.wpgraphql.page.AllPageElements.pageelements[1],
    data.wpgraphql.page.AllPageElements.pageelements[2],
    data.wpgraphql.page.AllPageElements.pageelements[3],
    data.wpgraphql.page.AllPageElements.pageelements[4],
    data.wpgraphql.page.AllPageElements.pageelements[5],
    data.wpgraphql.page.AllPageElements.pageelements[6],
    data.wpgraphql.menus.edges[0].node.menuItems.edges
  ]
}
