import React from 'react'
import { array } from 'prop-types'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import colors from '@styles/js/colors'

import Arrow from '@images/careers/arrow.svg'

const Positions = styled(({ data, className }) => (
  <ul className={`${className} mb-0`}>
    {data.map((item, index) => (
      <li key={index} className="d-inline-block w-100">
        <Link to={item.uri}>
          <span className="primary-text">{item.title}</span>
          <span className="secondary-text">Apply Now <Arrow /></span>
        </Link>
      </li>
    ))}
  </ul>
))`
  border-bottom: 1px solid #e5e5e5;
  list-style-type: disc;
  list-style: none;
  padding-bottom: 38px;
  padding-left: 0;
  padding-top: 40px;

  @media (min-width: 968px) {
    padding-bottom: 48px;
    padding-top: 49px;
  }

  li{
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    padding-left: 264px;
  }

  @media (min-width: 1250px) {
    padding-left: 380px;
  }

  a {
    align-items: flex-end;
    color: ${colors.black};
    display: flex;
    justify-content: space-between;
    text-decoration: none;

    &:hover {
      opacity: 0.6;
    }

    .primary-text {
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 0.03em;

      @media (min-width: 576px) {
      }
    }

    .secondary-text {
      font-size: 20px;
      line-height: 32px;
      padding-right: 72px;
      position: relative;

      @media (min-width: 768px) {
        padding-right: 76px;
      }

      &:after{
        background-color: #f5c141;
        content: '';
        display: block;
        height: 2px;
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
      }

      svg{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
`

Positions.propTypes = {
  data: array.isRequired
}

Positions.defaultProps = {
  data: []
}

export default Positions
