import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'

import Image from './components/Image'
import Body from './components/Body'

import Dots from '@images/careers/dot.svg'

const HeroSmall = styled(({ data, className }) => (
  <div className={`${className} position-relative w-100`}>
    <div className="dots"><Dots /></div>
    <Image />
    <Body data={data} />
  </div>
))`
  background: #2B58CA;
  padding: 48px 0;
  position: relative;
  margin-bottom: 52px;

  @media (min-width: 768px) {
    padding: 123px 0;
    margin-bottom: 85px;
  }

  @media (min-width: 1440px) {
    margin-bottom: 137px;
  }

  .dots{
    bottom: -43px;
    display: none;
    left: calc((100% - 720px) / 2  + 15px);
    position: absolute;

    @media (min-width: 768px) {
      display: block;
    }

    @media (min-width: 1024px) {
      left: calc((100% - 960px) / 2 + 15px);
    }

    @media (min-width: 1440px) {
      left: 50px;
    }
  }
`

HeroSmall.propTypes = {
  data: object.isRequired
}

HeroSmall.defaultProps = {
  data: {}
}

export default HeroSmall
