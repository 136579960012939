import React from 'react'
import styled from '@emotion/styled'
import Img from "gatsby-image"

import CareerShapeDesktop from '@images/careers/careers-desktop.svg'
import CareerShapeTablet from '@images/careers/careers-tablet.svg'

const Image = styled(({ className }) => {
  return (
    <div className={`${className} h-100`}>
      <div className="d-none d-xl-block"><CareerShapeDesktop /></div>
      <div className="d-none d-md-block d-xl-none"><CareerShapeTablet /></div>
    </div>
  )
})`
  position: absolute;
  top: 0;
  right: -5px;

  @media (min-width: 1440px) {
    right: 20px
  }

  svg{
    mask{
      mask-type:alpha
    }
  }
`


export default Image
