import React from "react"
import styled from "@emotion/styled"

import colors from "@styles/js/colors"

const Button = styled(({ onClick, index, className }) => {
  return (
    <button onClick={onClick} className={className}>
      {index}
    </button>
  )
})`
  background: #e5e5e5;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  font-size: 0;
  height: 10px;
  line-height: 0;
  margin-right: 12px;
  opacity: 1;
  outline: 0;
  padding: 0;
  transition: all 250ms linear;
  width: 10px;

  @media (min-width: 1250px) {
    //height: 16px;
    //width: 16px;
  }


  &.active {
    background: ${colors.saffron};
    opacity: 1;
  }

  &:hover {
    opacity: 1;
  }

  &:last-child {
    margin-right: 0;
  }
`

export default Button
