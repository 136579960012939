import styled from "@emotion/styled"

import colors from "@styles/js/colors"

const CollapseIcon = styled.div`
  display: inline-block;
  height: 32px;
  position: absolute;
  right: 0;
  top: 55%;
  transition: transform 0.35s ease-out;
  width: 32px;

  @media (min-width: 768px) {
    right: 0;
  }

  @media (min-width: 1280px) {
    right: 0;
  }

  &:after {
    background: #2B58CA;
    content: "";
    height: 4px;
    margin-top: -2px;
    opacity: 1;
    position: absolute;
    top: 50%;
    transition: opacity 0.35s ease-out;
    width: 32px;
  }

  &:before {
    background: #2B58CA;
    content: "";
    height: 32px;
    left: 50%;
    margin-left: -2px;
    position: absolute;
    width: 4px;
  }

  &.active {
    /* transform: rotate(45deg); */

    &::before {
      opacity: 0;
      /*background: ${colors.saffron}; */
    }
  }
`

export default CollapseIcon
