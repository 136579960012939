import React, { useState } from 'react'
import { array, object } from 'prop-types'
import { css, keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import { pulse, fadeIn } from 'react-animations'
import Img from 'gatsby-image'

import CarouselCustomIndex from '@components/CarouselCustomIndex'

import Triangle from './components/Triangle'

const Quotes = styled(({ className, content, images }) => {
  const [index, setIndex] = useState(0)
  const [motion, setMotion] = useState(false)

  const pulseAnimation = keyframes`${pulse}`
  const fadeInAnimation = keyframes`${fadeIn}`

  const transitionTime = 500

  const handleCurrent = key => {
    setIndex(key)
    setMotion(true)
    setTimeout(() => {
      setMotion(false)
    }, transitionTime + 50)
  }

  return (
    <div
      className={`${className} d-md-flex position-relative pt-0`}
    >
      <div className="position-relative image w-100 col-md-5 col-12 p-0">
        <Triangle />
        <div
          className="image-wrapper bg-white"
          css={
            motion
              ? css`
                  animation: ${transitionTime}ms ${pulseAnimation};
                `
              : ''
          }
        >
          <Img
            className="bg-img"
            fluid={images[index].image.imageFile.childImageSharp.fluid}
            alt={
              images[index].image.altText
                ? images[index].image.altText
                : images[index].title
            }
            key={index}
          />
        </div>
      </div>
      <div className="content d-flex align-items-center col-md-7 col-12">
        <div className="bar-block">
          <span className="bar-title">
            0{index + 1} - <span>0{images.length}</span>
          </span>
        </div>
        <div
          className="contact-inner-wrap"
          css={
            motion
              ? css`
                  animation: ${transitionTime}ms ${fadeInAnimation};
                `
              : ''
          }
        >
          <div className="content-inner w-100">
            <p className="font-weight-light font-20 text-white author">
              {content[index].title}
              <span className="position">
                &nbsp;- {content[index].position}
              </span>
            </p>
            <h2 className="text-white font-weight-light">
              {content[index].alt}
            </h2>
            <p className="text-white mb-0 sub-text">{content[index].content}</p>
          </div>

          <div className="dots-block d-inline-block w-100">
            {images.map((item, key) => (
              <CarouselCustomIndex
                key={key}
                index={key}
                onClick={() => handleCurrent(key)}
                className={index === key ? 'active' : ''}
              >
                {key}
              </CarouselCustomIndex>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
})`
  &:before {
    background-color: #2b58ca;
    content: '';
    display: none;
    height: 100%;
    right: 0;
    position: absolute;
    top: 0;
    z-index: 0;
    width: calc(100% - 39px);

    @media (min-width: 768px) {
      display: block;
    }
    
    @media (min-width: 1200px) {
      width: calc(100% - 165px);
    }
  }

  .image {
    z-index: 1;
    padding-right: 10px !important;
    position: relative;

    @media (min-width: 768px) {
      align-self: flex-start;
      top: -42px;
      padding-right: 0 !important;
      max-width: 263px;
    }

    @media (min-width: 768px) {
      max-width: 350px;
    }

    @media (min-width: 1200px) {
      top: -38px;
      padding-right: 0 !important;
      max-width: 720px;
    }
  }

  .image-wrapper {
    height: 230px;

    @media (min-width: 567px) {
      height: 385px;
    }

    @media (min-width: 768px) {
      height: 385px;
    }

    @media (min-width: 996px) {
      height: 450px;
    }

    @media (min-width: 1250px) {
      height: 560px;
      width: 650px;
    }

    .bg-img {
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.15);
      position: absolute;
      height: 100%;
      top: 50%;
      transform: translateY(-50%);

      @media (min-width: 992px) {
        width: 100%;
      }
    }
  }

  .content {
    background-color: #2b58ca;
    margin-top: 0;
    padding: 0 0 0 0;
    position: static;

    @media (min-width: 768px) {
      //min-height: 450px;
      min-height: 100%;
      position: relative;
    }

    > div {
      z-index: 2;
    }

    .bar-block {
      padding-left: 36px;
      position: absolute;
      right: 0;
      top: -40px;
      width: 100%;

      @media (min-width: 768px) {
        padding-left: 46px;
        top: -35px;
      }

      @media (min-width: 1250px) {
        overflow: hidden;
        padding-left: 117px;
      }

      > span {
        background-color: #fff;
        padding-right: 1rem;
        position: relative;
        z-index: 2;

        span {
          color: #a7a5a5;
        }
      }

      &:before {
        background-color: #f5c141;
        content: '';
        height: 2px;
        left: 5rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;

        @media (min-width: 1250px) {
          left: 8rem;
        }
      }
    }

    @media (min-width: 768px) {
      .contact-inner-wrap {
        //position: relative;
        z-index: 0;

        @media (min-width: 1250px) {
          overflow: hidden;
        }
      }
    }

    .content-inner {
      padding: 46px 20px 24px 20px;

      @media (min-width: 768px) {
        padding: 64px 17px 24px 46px;
      }

      @media (min-width: 1250px) {
        padding: 150px 153px 48px 117px;
      }

      .author {
        margin-bottom: 16px;
        line-height: 24px;

        .position {
          display: none;

          @media (min-width: 1250px) {
            display: inline-block;
          }
        }
      }

      h2 {
        font-size: 32px;
        font-weight: 300;
        line-height: 37px;
        margin-bottom: 16px;

        @media (min-width: 568px) {
          line-height: 1.21;
        }

        @media (min-width: 1250px) {
          font-size: 48px;
          line-height: 58px;
          margin-bottom: 32px;
        }
      }

      .sub-text {
        font-size: 16px;
        line-height: 24px;
        font-stretch: normal;
        font-weight: normal;
        letter-spacing: 0.03em;
      }
    }
  }

  .dots-block {
    padding-left: 20px;
    margin-bottom: 42px;
    display: flex !important;

    @media (min-width: 768px) {
      padding-left: 46px;
      margin-bottom: 73px;
    }

    @media (min-width: 1250px) {
      padding-left: 117px;
      margin-bottom: 155px;
    }
  }
`

Quotes.propTypes = {
  content: array.isRequired,
  fluid: object.isRequired
}

Quotes.defaultProps = {
  content: [],
  fluid: {}
}

export default Quotes
