import styled from "@emotion/styled"

import colors from "@styles/js/colors"

const Triangle = styled.div`
  background: linear-gradient(
    to bottom right,
    transparent 0%,
    transparent 50%,
    ${colors.saffron} 50%,
    ${colors.saffron} 99%
  );
  bottom: -10px;
  height: 90%;
  max-width: 70%;
  position: absolute;
  right: -1rem;
  top: auto;
  width: 100%;


  @media (min-width: 768px) {
    bottom: -10px;
    height: 206px;
    right: -10px;
    max-width: 209px;
  }

  @media (min-width: 1200px) {
    bottom: -1rem;
    right: -1rem;
    width: 70%;
  }


`

export default Triangle
