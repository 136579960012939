import React, { useState } from 'react'
import { object, number } from 'prop-types'
import Collapse from 'react-bootstrap/Collapse'

import Body from './components/Body'
import Positions from './components/Positions'

const CollapsePosition = ({ data, fluid, index }) => {
  const [open, setOpen] = useState(false)

  return (
    <div className="d-inline-block w-100">
      <div
        onClick={() => setOpen(!open)}
        onKeyDown={() => setOpen(!open)}
        aria-controls={`collapse-position-${index}`}
        aria-expanded={open}
        role="button"
        tabIndex={0}
      >
        <Body data={data} fluid={fluid} open={open} />
      </div>
      <Collapse in={open}>
        <Positions data={data.careers.nodes} />
      </Collapse>
    </div>
  )
}

CollapsePosition.propTypes = {
  data: object.isRequired,
  fluid: object.isRequired,
  index: number.isRequired
}

export default CollapsePosition
