import React from 'react'
import { object } from 'prop-types'
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'

// components
import CollapseIcon from './Components/CollapseIcon'

const Body = styled(({ data, open, fluid, className }) => (
  <div className={`${className} d-flex`}>
    <BackgroundImage
      className="image d-none d-md-block"
      fluid={fluid}
      alt={
        data.careerCategoryImage.cCategoryImage.altText
          ? data.careerCategoryImage.cCategoryImage.altText
          : data.name
      }
    />
    <div className="content w-100">
      <p className="font-20 text-gray">
        {data.careers.nodes.length}
        {data.careers.nodes.length >= 2 ? ' openings' : ' opening'}
      </p>
      <div className="d-flex align-items-center">
        <span className="h2 mb-0">{data.name}</span>
        <CollapseIcon className={open ? 'active' : ''} />
      </div>
    </div>
  </div>
))`
  overflow: hidden;
  cursor: pointer;

  @media (min-width: 768px) {
    position: relative;
  }

  .image {
    background-position: center;
    background-size: cover;
    max-width: 210px;
    min-height: 127px;
    width: 100%;

    @media (min-width: 992px) {
      max-width: 326px;
      min-height: 197px;
    }
  }

  .content {
    align-self: center;
    position: relative;

    @media (min-width: 768px) {
      margin-left: 54px;
      position: static;
      width: auto;
    }

    .h2 {
      font-size: 32px;
      font-weight: 300;
      line-height: 37px;

      @media (min-width: 768px) {
        font-size: 48px;
        line-height: 56px;
      }
    }

    p {
      color: #8e8e8e;
      font-size: 20px;
      line-height: 32px;
      font-weight: 300;
    }
  }
`

Body.propTypes = {
  data: object.isRequired,
  fluid: object.isRequired
}

Body.defaultProps = {
  data: {},
  fluid: {}
}

export default Body
