import React from 'react'

// compositions
import Layout from '@compositions/Layout'
import Careers from '@compositions/Careers'

const CareersPage = () => {
  return (
    <Layout>
      <Careers />
    </Layout>
  )
}

export default CareersPage
